import React, { useState, useContext } from 'react';
import axios from 'axios';
import { navigate, Link } from 'gatsby';

import SEO from '../blocks/layout/seo';
import { Input, Button, message } from 'antd';
import { FiSearch } from 'react-icons/fi';
import { FaToggleOff } from 'react-icons/fa';
import { GrPlay } from 'react-icons/gr';
import { GiClick } from 'react-icons/gi';
import AppContainer from '../blocks/layout';

import { list } from '../data/applications';
import { Firebase } from '../config/firebase';
import { GlobalDispatchContext, GlobalStateContext } from '../context/GlobalContextProvider';

import '../styles/marketplace.scss';

// markup
const Marketplace = () => {
  const [appList, setAppList] = useState(list);
  const [addingLoader, setAddingLoader] = useState(false);
  const [addingAppId, setAddingAppId] = useState('');
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  const { projects, selectedProjectId } = state;

  const filterAppList = (e) => {
    const filterList = [];
    const targetValue = e.target.value.toLocaleLowerCase();
    if (targetValue !== '') {
      list.forEach((app) => {
        const isMatch = app.name.toLocaleLowerCase().search(targetValue);
        if (isMatch > -1) filterList.push(app);
      });
      setAppList(filterList);
    } else {
      setAppList(list);
    }
  };

  const isAppActivated = (id) => {
    const isExist = projects
      ?.find((p) => p._id == selectedProjectId)
      ?.apis_list.findIndex((x) => x.id === id);
    if (isExist > -1) {
      return true;
    }
    return false;
  };

  /**
   * Get projects
   *
   * @param {*} uid
   */
  const getProjects = (uid) => {
    axios.get(`${process.env.GATSBY_BASE_URL}/projects/${uid}`).then((response) => {
      dispatch({
        type: 'SET_PROJECTS',
        projects: response.data,
      });
    });
  };

  const activateAppToUserAccount = (appObj) => {
    setAddingLoader(true);
    setAddingAppId(appObj.id);
    if (appObj !== undefined) {
      Firebase.auth().onAuthStateChanged((user) => {
        const { name, id, description } = appObj;
        if (user) {
          const objToAdd = {
            name,
            id,
            description,
          };
          axios
            .post(
              `${process.env.GATSBY_BASE_URL}/apps`,
              {
                appInfo: objToAdd,
                projectId: selectedProjectId,
                uid: user.uid,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              },
            )
            .then((res) => {
              setAddingLoader(false);
              getProjects(user.uid);
              navigate(`/dashboard/api/${appObj.id}`);
            })
            .catch((error) => {
              setAddingLoader(false);
              setAddingAppId('');
              message.warning(error.response.data.message);

              // console.log(error.response.status)
              // console.log(error.response.headers)
            });
        } else {
          navigate('/signup');
        }
      });
    }
  };

  return (
    <>
      <SEO title='Marketplace — NoCodeAPI' />
      <AppContainer activeNav='marketplace'>
        <div className='page-content-header'>
          <h2>Marketplace</h2>
          <p>Find application & activate to setup their API</p>
          <Input
            placeholder='search applications'
            prefix={<FiSearch />}
            onChange={filterAppList}
            size='large'
          />
        </div>
        {appList.length > 0 ? (
          <div className='app-list'>
            {appList.map((app, key) => (
              <div className='app-card' key={key}>
                <div className='card-content'>
                  <div className='app-name-logo'>
                    <img src={`/logos/${app.logo}`} alt={app.name} height='30px' />
                    <h3>{app.name}</h3>
                  </div>
                  <p>{app.description}</p>
                  {isAppActivated(app.id) ? (
                    <Link to={`/dashboard/api/${app.id}`}>
                      <Button
                        style={{
                          fontSize: 13,
                          fontWeight: 300,
                        }}
                        icon={<GrPlay />}
                      >
                        Use this API
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      type='primary'
                      style={{
                        fontSize: 13,
                        fontWeight: 300,
                      }}
                      loading={addingLoader && addingAppId === app.id}
                      onClick={() => activateAppToUserAccount(app)}
                      icon={<FaToggleOff />}
                    >
                      Activate
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className='request-section'>
            <p>
              <GiClick /> Can’t find an API? Send us your request here --{' '}
              <b>
                <a href='https://nocodeapi.com/contact' target='_blank'>
                  Request New Integration
                </a>
              </b>{' '}
              & we will try our best to add that ASAP.
            </p>
          </div>
        )}
      </AppContainer>
    </>
  );
};

export default Marketplace;
